import React from 'react';
import Modal from '@justpark/ui/src/components/Modal';
import Typography from '@justpark/ui/src/components/Typography';
import CrossIcon from '@justpark/ui/src/components/Icons/CrossIcon';
import type { Props as SharedProps } from './BookingPanel';

import styles from '../../../static/scss/components/listingDetails/bookSpaceModal.module.scss';

type Props = SharedProps & {
  isOpen: boolean;
  onClose: () => void;
};

const BookSpaceModal = ({ isOpen, onClose, t, children, isMonthly }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    portalParent="#portal"
    closeOnOutsideClick
    id="book-space-modal"
  >
    <div className={styles.wrapper} data-cy="book-space-modal">
      <div>
        <CrossIcon onClick={onClose} className={styles.close} />
        <Typography className={styles.heading} bold>
          <div>
            {t('listingDetails:checkAvailability', 'Check availability')}
          </div>
        </Typography>
        <Typography className={styles.description} size="secondary">
          <div>
            {!isMonthly
              ? t(
                  'listingDetails:bookingModalDescription',
                  'Choose the days and times when you want to park your vehicle'
                )
              : t(
                  'listingDetails:monthlyDescription',
                  'Book this space on a long-term basis with a subscription for your vehicle'
                )}
          </div>
        </Typography>
      </div>
      <div className={styles.bookingInformation}>{children}</div>
    </div>
  </Modal>
);

export default BookSpaceModal;
